class ZIDXAccountInvoice implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountInvoiceContainer";
    }
    getMatchURL(){
        return "/account/payment/invoice";
    }
    render(){
        // console.log("invoice");
    }
}